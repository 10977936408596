// src/components/ProjetosList.js
import React from 'react';
import '../styles/ProjetosList.css';

const ProjetosList = ({ projetos }) => (
  <div className="projetos-list">
    {projetos.map((projeto) => (
      <div key={projeto.id} className="projeto-card">
        <h3>{projeto.nome}</h3>
        <p>{projeto.descrição}</p>
        <ul className="projeto-detalhes">
          {projeto.detalhes.map((detalhe, index) => (
            <li key={index}>{detalhe}</li>
          ))}
        </ul>
        <div className="projeto-imagens">
          {projeto.images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`${projeto.nome} imagem ${index + 1}`}
              className="projeto-image"
            />
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default ProjetosList;
