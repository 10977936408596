// src/components/AboutMe.js
import React from 'react';
import '../styles/AboutMe.css';
import fotoPerfil from '../perfil.jpg'; // Substitua pelo caminho da sua imagem

const AboutMe = () => {
  return (
    <div className="about-me-container">
      <img src={fotoPerfil} alt="Foto de Nicholas" className="about-me-photo" />
      <div className="about-me-text">
        <p>
          Olá! Eu sou Nicholas, um entusiasta de tecnologia com uma paixão profunda pela inovação e pela exploração das possibilidades que a tecnologia pode oferecer.
        </p>
        <p>
          Minha jornada me levou a explorar diversas áreas da tecnologia, desde o desenvolvimento de soluções criativas até a construção de plataformas que conectam pessoas e expandem os limites da comunicação e da criatividade.
        </p>
        <p>
          Acredito que a tecnologia deve servir como uma ferramenta para empoderar indivíduos e comunidades, e estou sempre em busca de novas maneiras de fazer isso acontecer.
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
