// src/components/FestasList.js
import React, { useState } from 'react';
import '../styles/FestasList.css';

const FestasList = ({ festas }) => (
  <div className="festas-list">
    {festas.map((festa) => (
      <div key={festa.id} className="festa-card">
        <h3>{festa.nome}</h3>
        <p>{festa.descrição}</p>
        <ul className="festa-detalhes">
          {festa.detalhes.map((detalhe, index) => (
            <li key={index}>{detalhe}</li>
          ))}
        </ul>
        <ImageCarousel images={festa.images} />
      </div>
    ))}
  </div>
);

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="carousel-container">
      <button className="carousel-button left" onClick={goToPrevious}>
        &#9664;
      </button>
      <img
        src={images[currentIndex]}
        alt={`Imagem ${currentIndex + 1}`}
        className="carousel-image"
      />
      <button className="carousel-button right" onClick={goToNext}>
        &#9654;
      </button>
    </div>
  );
};

export default FestasList;
