// src/components/NoticiasList.js
import React from 'react';
import '../styles/NoticiasList.css';

const NoticiasList = ({ noticias }) => (
  <div className="noticias-list">
    {noticias.map((noticia) => (
      <div key={noticia.id} className="noticia-card">
        <h3>{noticia.titulo}</h3>
        <p>{noticia.conteudo}</p>
      </div>
    ))}
  </div>
);

export default NoticiasList;
