// src/data/data.js

export const projetos = [
    {
      id: 1,
      nome: 'QBCore Framework',
      descrição: 'Tive a honra de participar de uma das frameworks pioneiras na comunidade do FiveM, trazendo modificações, sugestões, melhorias e traduções.',
      detalhes: [
        "Funcionalidade: Framework para servidores privados.",
        "Tecnologia: Componente é utilizado como um mecânismo para servidores, de maneira gratuíta, poderem se estabelecer.",
        "Benefícios: Atualizações que acontecem diariamente até hoje, dando a oportunidade de servidores privados de FiveM, estarem em servidores atualizados."
      ],
      images: ['/images/projeto1.jpg', '/images/projeto2.jpg']
    },
    {
      id: 2,
      nome: 'CidadeZion',
      descrição: 'O projeto foi realizado com o intuíto de mesclar minha experiência de vida num ambiente digital, um metaverso onde eu pudesse gerar eventos multiculturais.',
      detalhes: [
        "Funcionalidade: Facilita a conexão entre artistas e ouvintes através de NFTs.",
        "Tecnologia: Utiliza IPFS para armazenamento descentralizado e contratos inteligentes em Ethereum.",
        "Benefícios: Proporciona maior liberdade aos artistas, permitindo monetização direta e controle total sobre seus conteúdos."
      ],
      images: ['/images/projeto1.jpg', '/images/projeto2.jpg']
    },
    {
      id: 3,
      nome: 'DOGMA Framework - Web3',
      descrição: 'Plataforma de música Web3 que conecta artistas e ouvintes através de NFTs.',
      detalhes: [
        "Funcionalidade: Facilita a conexão entre artistas e ouvintes através de NFTs.",
        "Tecnologia: Utiliza IPFS para armazenamento descentralizado e contratos inteligentes em Ethereum.",
        "Benefícios: Proporciona maior liberdade aos artistas, permitindo monetização direta e controle total sobre seus conteúdos."
      ],
      images: ['/images/projeto1.jpg', '/images/projeto2.jpg']
    },
    {
      id: 4,
      nome: 'ORONewLife',
      descrição: 'Foi fundada em 2016 com o intuíto de revolucionar o mercado de suplementos internacional, trazendo tecnologias jamais vistas no mercado de suplementação',
      detalhes: [
        "Funcionalidade: Plataforma para acessibilidade ao usuário e revendedor.",
        "Tecnologia: Shopify.",
        "Benefícios: Proporciona maior liberdade aos revendedores, permitindo monetização direta e controle total sobre suas vendas."
      ],
      images: ['/images/projeto1.jpg', '/images/projeto2.jpg']
    },
    // Adicione mais projetos conforme necessário
  ];
 
  export const festas = [
    {
      id: 5,
      nome: 'Festival Trancedance',
      descrição: 'Realização do festival foi um marco para mim, um desenvolvimento afrontoso para a época que pôs aprova todos os meus limites em desenvolvimento de projeto..',
      detalhes: [
        "Realizado em: 01/07/2016",
        "Local: Prudentópolis/PR",
        "Atrações: Banda ABC, DJ 123"
      ],
      images: ['/images/trancedance1.jpg', '/images/trancedance2.jpg', '/images/trancedance-lineup.jpg']
    },
    {
      id: 6,
      nome: 'PVT Secret L25',
      descrição: 'Participei da realização da PVT Secret L25, no qual participava de maneira altruísta do movimento psicodélico no Brasil, onde comemoramos aniversário de 10 anos do grupo, onde tinhamos proposta, reduzir os danos em relação as drogas!',
      detalhes: [
        "Realizado em: 01/07/2016",
        "Local: Minas Gerais",
        "Atrações: Banda ABC, DJ 123"
      ],
      images: ['/images/pvtl251.jpg', '/images/pvtl251lineup.jpg']
    },
    {
      id: 7,
      nome: 'Ritual Irgum Burgum',
      descrição: 'Tive a honra de participar de maneira altruísta do Ritual Irgum Burgum, tendo uma das maiores experiências que tive na vida. Durante, tive a oportunidade de cozinhar macarrão para os fundadores do Ozora, isso me preenche até hoje!',
      detalhes: [
        "Realizado em: 01/07/2016",
        "Local: São Paulo",
        "Atrações: Banda ABC, DJ 123"
      ],
      images: ['/images/festa1.jpg', '/images/festa2.jpg']
    },
    // Adicione mais festas conforme necessário
  ];
  
  export const noticias = [
    {
      id: 1,
      titulo: 'Atualização do portifólio',
      conteudo: 'Meu portifólio estava muito pesado, apesar da proposta boa, o desempenho estava péssimo.'
    },
    {
      id: 2,
      titulo: 'Atualização projeto DOGMA',
      conteudo: 'Pouco a pouco, estamos idealizando uma plataforma Web3, desafiando ainda mais minha capacidade de desenvolvimento.'
    },
    {
      id: 3,
      titulo: 'Melhorias na comunidade dMario',
      conteudo: 'Em prol de melhorias, algumas modificações na comunidade foram realizadas.'
    },
    {
      id: 4,
      titulo: 'Organização do Github',
      conteudo: 'Em prol de melhorias, algumas modificações na comunidade foram realizadas.'
    },
    // Adicione mais notícias conforme necessário
  ];
  