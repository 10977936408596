import React, { useState } from 'react';
import { FaUser, FaProjectDiagram, FaEnvelope, FaGithub, FaDiscord, FaGamepad } from 'react-icons/fa';
import Window from '../components/Window';
import Navbar from '../components/Navbar';
import '../styles/Home.css';
import AboutMe from '../components/AboutMe';
import ContactForm from '../components/ContactForm';
import Projetos from './Projetos';
import Servidores from './Servidores';
import PinballGame from '../components/PinballGame';

const Home = () => {
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isPinballOpen, setIsPinballOpen] = useState(false);
  const [isServersOpen, setIsServersOpen] = useState(false); // Novo estado para Servidores
  const [isWindowOpen, setIsWindowOpen] = useState(true);

  const openAboutWindow = () => setIsAboutOpen(true);
  const openProjectsWindow = () => setIsProjectsOpen(true);
  const openContactWindow = () => setIsContactOpen(true);
  const openPinballWindow = () => setIsPinballOpen(true);
  const openServersWindow = () => setIsServersOpen(true); // Função para abrir janela de Servidores

  return (
    <div className="desktop">
      <Navbar 
        openAboutWindow={openAboutWindow}
        openProjectsWindow={openProjectsWindow} 
        openContactWindow={openContactWindow}
        openServersWindow={openServersWindow} // Passa a função para abrir Servidores
      />

      {/* Ícones do Desktop */}
      <div className="desktop-icons">
        <div className={`desktop-icon ${isAboutOpen ? 'selected' : ''}`} onClick={openAboutWindow}>
          <FaUser size={50} />
          <p>Sobre Mim</p>
        </div>
        <div className={`desktop-icon ${isProjectsOpen ? 'selected' : ''}`} onClick={openProjectsWindow}>
          <FaProjectDiagram size={50} />
          <p>Projetos</p>
        </div>
        <div className={`desktop-icon ${isContactOpen ? 'selected' : ''}`} onClick={openContactWindow}>
          <FaEnvelope size={50} />
          <p>Contato</p>
        </div>
        <div className="desktop-icon" onClick={() => window.open('https://github.com/ErgothStudio', '_blank')}>
          <FaGithub size={50} />
          <p>GitHub</p>
        </div>
        <div className="desktop-icon" onClick={() => window.open('https://dsc.gg/dmario', '_blank')}>
          <FaDiscord size={50} />
          <p>Discord</p>
        </div>
        <div className={`desktop-icon ${isPinballOpen ? 'selected' : ''}`} onClick={openPinballWindow}>
          <FaGamepad size={50} />
          <p>Pinball</p>
        </div>
      </div>

      {/* Janelas Modais */}
      {isAboutOpen && (
        <Window title="Sobre Mim" onClose={() => setIsAboutOpen(false)}>
          <AboutMe />
        </Window>
      )}
      {isProjectsOpen && (
        <Window title="Projetos" onClose={() => setIsProjectsOpen(false)} style={{ width: '60%', height: '80%' }}>
          <Projetos />
        </Window>
      )}
      {isContactOpen && (
        <Window title="Entre em Contato" onClose={() => setIsContactOpen(false)}>
          <ContactForm />
        </Window>
      )}
      {isServersOpen && (
        <Window title="Servidores e Comunidades" onClose={() => setIsServersOpen(false)} style={{ width: '60%', height: '80%' }}>
          <Servidores />
        </Window>
      )}
      {isPinballOpen && (
        <Window title="Pinball" onClose={() => setIsPinballOpen(false)} style={{ width: '70%', height: '80%' }}>
          <PinballGame />
        </Window>
      )}
      {/* Janela modal de boas-vindas */}
      {isWindowOpen && (
        <Window
          title="Bem-vindo"
          onClose={() => setIsWindowOpen(false)}
          style={{
            width: '300px',
            top: '50px',
            right: '100px',
            position: 'absolute'
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <img src="windowgif1.gif" alt="Welcome GIF" style={{ width: '100%', maxWidth: '280px' }} />
            <p>Bem-vindo ao meu portfólio</p>
          </div>
        </Window>
      )}
    </div>
  );
};

export default Home;
