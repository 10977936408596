// src/pages/Projetos.js
import React, { useState } from 'react';
import RadioPlayer from '../components/RadioPlayer';
import ProjetosList from '../components/ProjetosList';
import FestasList from '../components/FestasList';
import NoticiasList from '../components/NoticiasList';
import '../styles/Projetos.css';
import { projetos, festas, noticias } from '../data/data';

const Projetos = () => {
  const [conteudoAtivo, setConteudoAtivo] = useState('noticias');

  const renderConteudo = () => {
    switch (conteudoAtivo) {
      case 'projetos':
        return <ProjetosList projetos={projetos} />;
      case 'festas':
        return <FestasList festas={festas} />;
      case 'noticias':
        return <NoticiasList noticias={noticias} />;
      default:
        return null;
    }
  };

  return (
    <div className="projetos-navegador">
      <div className="navegador-janela">
        <header className="navegador-header">
          <div className="navegador-buttons">
            <button className="btn btn-close" aria-label="Close">X</button>
            <button className="btn btn-minimize" aria-label="Minimize">-</button>
            <button className="btn btn-maximize" aria-label="Maximize">+</button>
          </div>
          <div className="navegador-barra-endereco">
            <span>https://myprojects.brain/</span>
          </div>
        </header>

        <div className="navegador-conteudo">
          <div className="site-header">
            <h1>Welcome to my brain!</h1>
            <marquee behavior="scroll" direction="left">Confira nossas últimas novidades!</marquee>
          </div>

          <div className="layout-container">
            <div className="menu-lateral">
              <h3>Menu</h3>
              <ul>
                <li
                  className={conteudoAtivo === 'noticias' ? 'active' : ''}
                  onClick={() => setConteudoAtivo('noticias')}
                >
                  NOTÍCIAS
                </li>
                <li
                  className={conteudoAtivo === 'projetos' ? 'active' : ''}
                  onClick={() => setConteudoAtivo('projetos')}
                >
                  PROJETOS
                </li>
                <li
                  className={conteudoAtivo === 'festas' ? 'active' : ''}
                  onClick={() => setConteudoAtivo('festas')}
                >
                  EVENTOS
                </li>
              </ul>
            </div>

            <div className="conteudo-central">
              {renderConteudo()}
            </div>

            <div className="widget-lateral">
            </div>
          </div>

          <footer className="site-footer">
            <p>&copy; 1998 My 🧠Brain. Todos os direitos reservados.</p>
            <p><a href="mailto:nicholas@dmar.io">nicholas@dmar.io</a></p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Projetos;
