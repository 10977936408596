// src/components/PinballGame.js
import React from 'react';

const PinballGame = () => {
  return (
    <iframe
      src="https://pinballgame.io/" // URL de um jogo de pinball em HTML5
      title="Pinball Game"
      width="100%"
      height="100%"
      style={{
        border: 'none',
        backgroundColor: '#000',
      }}
    ></iframe>
  );
};

export default PinballGame;
