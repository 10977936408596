import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Projetos from './pages/Projetos';
import Servidores from './pages/Servidores';
import Navbar from './components/Navbar';
import LoadingScreen from './components/LoadingScreen';
import './styles/global.css';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoaded = () => {
    setIsLoading(false); // Desativa a tela de carregamento
  };

  return (
    <Router>
      {isLoading ? (
        <LoadingScreen onLoaded={handleLoaded} />
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projetos" element={<Projetos />} />
            <Route path="/servidores" element={<Servidores />} />
          </Routes>
        </>
      )}
    </Router>
  );
};

export default App;
