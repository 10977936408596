// src/components/Window.js
import React, { useRef, useState, useEffect } from 'react';
import { FaTimes, FaWindowMinimize } from 'react-icons/fa';
import '../styles/global.css';

let highestZIndex = 1; // Variável global para rastrear o `z-index` mais alto

const Window = ({ title, children, onClose, position = {}, style }) => {
  const windowRef = useRef(null);
  const [isMinimized, setIsMinimized] = useState(false);
  const [zIndex, setZIndex] = useState(highestZIndex); // Estado para armazenar o `z-index` atual da janela

  // Função para trazer a janela para a frente ao clicar nela
  const bringToFront = () => {
    highestZIndex += 1;
    setZIndex(highestZIndex); // Atualiza o `z-index` da janela
  };

  const handleDragStart = (e) => {
    e.preventDefault();
    const offsetX = e.clientX - windowRef.current.getBoundingClientRect().left;
    const offsetY = e.clientY - windowRef.current.getBoundingClientRect().top;

    const handleMouseMove = (moveEvent) => {
      windowRef.current.style.left = `${moveEvent.clientX - offsetX}px`;
      windowRef.current.style.top = `${moveEvent.clientY - offsetY}px`;
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener(
      'mouseup',
      () => {
        document.removeEventListener('mousemove', handleMouseMove);
      },
      { once: true }
    );
  };

  const handleMinimize = () => setIsMinimized(!isMinimized);

  return (
    <div
      className="window"
      ref={windowRef}
      style={{
        ...position,
        ...style,
        zIndex: zIndex, // Aplica o z-index atual da janela
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
        bringToFront(); // Traz a janela para frente ao clicar
      }}
    >
      <div className="window-header" onMouseDown={handleDragStart}>
        <div className="window-title">{title}</div>
        <div className="window-controls">
          <FaWindowMinimize className="window-icon" onClick={handleMinimize} />
          <FaTimes className="window-icon" onClick={onClose} />
        </div>
      </div>
      {!isMinimized && <div className="window-content">{children}</div>}
    </div>
  );
};

export default Window;
