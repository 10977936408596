import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaWindows } from 'react-icons/fa';
import '../styles/Navbar.css';

const Navbar = ({ openAboutWindow, openProjectsWindow, openContactWindow, openServersWindow }) => {
  const [isStartMenuOpen, setStartMenuOpen] = useState(false);
  const [dateTime, setDateTime] = useState('');

  const toggleStartMenu = () => {
    setStartMenuOpen(!isStartMenuOpen);
  };

  const handleMenuClick = (action) => {
    action();
    setStartMenuOpen(false); // Fecha o menu "Iniciar" após o clique
  };

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
      const formattedTime = now.toLocaleTimeString([], options);
      const formattedDate = now.toLocaleDateString();
      setDateTime(`${formattedDate} ${formattedTime}`);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <nav className="navbar-container">
      {/* Botão "Iniciar" */}
      <button className="start-button" onClick={toggleStartMenu}>
        <FaWindows size={16} style={{ marginRight: '5px' }} />
        Iniciar
      </button>

      {/* Menu "Iniciar" */}
      {isStartMenuOpen && (
        <div className="start-menu">
          <ul>
            <li><Link to="/" onClick={() => setStartMenuOpen(false)}>🖥️ Meu Computador</Link></li>
            <li onClick={() => handleMenuClick(openProjectsWindow)}>📂 Projetos</li>
            <li onClick={() => handleMenuClick(openServersWindow)}>🌐 Servidores</li>
            <li onClick={() => handleMenuClick(openContactWindow)}>📧 Contato</li>
            <li onClick={() => handleMenuClick(openAboutWindow)}>ℹ️ Sobre</li>
          </ul>
        </div>
      )}

      {/* Links de Navegação Rápida */}
      <div className="navbar-links">
        <button className="navbar-link" onClick={openProjectsWindow}>Projetos</button>
        <button className="navbar-link" onClick={openServersWindow}>Servidores</button>
        <button className="navbar-link" onClick={openContactWindow}>Contato</button>
      </div>

      {/* Relógio com Data e Hora */}
      <div className="clock">{dateTime}</div>
    </nav>
  );
};

export default Navbar;
