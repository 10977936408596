import React, { useEffect, useState } from 'react';
import '../styles/loadingScreen.css';

const LoadingScreen = ({ onLoaded }) => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("Inicializando sistema...");
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(timer);
          setStatus("Carregamento concluído");

          // Iniciar esvanecimento e chamar onLoaded imediatamente
          setIsFadingOut(true);
          onLoaded(); // Chama onLoaded imediatamente para que o conteúdo principal possa começar a aparecer
          return 100;
        }

        // Alterar mensagens conforme o progresso
        if (prev > 80) setStatus("Carregando interface...");
        else if (prev > 50) setStatus("Verificando arquivos...");
        else if (prev > 30) setStatus("Iniciando serviços...");
        return prev + 5;
      });
    }, 300);

    return () => clearInterval(timer);
  }, [onLoaded]);

  return (
    <div className={`loading-screen ${isFadingOut ? 'fade-out' : ''}`}>
      <div className="boot-screen">
        <p className="boot-text">DOGMA OS v1.0</p>
        <p className="boot-text">{status}</p>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }} />
        </div>
        <p className="boot-text">{progress}% concluído</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
