import React, { useState } from 'react';
import '../styles/Servidores.css';
import { FaSyncAlt } from 'react-icons/fa';
import { comunidades, jogos } from '../data/servidoresData';

const Servidores = () => {
  const [conteudoAtivo, setConteudoAtivo] = useState('comunidades');
  const [searchTerm, setSearchTerm] = useState('');

  const servidores = conteudoAtivo === 'comunidades' ? comunidades : jogos;
  const filteredServidores = servidores.filter(servidor =>
    servidor.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="servidores-janela">
      <header className="servidores-header">
        <div className="tabs">
          <button className="tab active">Internet</button>
          <button className="tab">Favorites</button>
          <button className="tab">History</button>
          <button className="tab">Spectate</button>
          <button className="tab">LAN</button>
          <button className="tab">Friends</button>
        </div>
      </header>

      <div className="servidores-menu">
        <button
          className={`menu-botao ${conteudoAtivo === 'comunidades' ? 'ativo' : ''}`}
          onClick={() => setConteudoAtivo('comunidades')}
        >
          Comunidades
        </button>
        <button
          className={`menu-botao ${conteudoAtivo === 'jogos' ? 'ativo' : ''}`}
          onClick={() => setConteudoAtivo('jogos')}
        >
          Jogos
        </button>
        <input
          type="text"
          placeholder="Buscar servidores..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="servidores-busca"
        />
        <button className="refresh-botao"><FaSyncAlt /></button>
      </div>

      <div className="servidores-lista">
        <div className="servidores-lista-header">
          <span className="header-coluna">Servidor</span>
          <span className="header-coluna">Jogo</span>
          <span className="header-coluna">Jogadores Online</span>
          <span className="header-coluna">Ping</span>
        </div>
        {filteredServidores.map((servidor) => (
          <a
            key={servidor.id}
            href={servidor.link}
            target="_blank"
            rel="noopener noreferrer"
            className="servidor-item-link"
          >
            <div className="servidor-item">
              <span className="servidor-info">{servidor.nome}</span>
              <span className="servidor-info">{servidor.jogo}</span>
              <span className="servidor-info">
                {servidor.jogadoresOnline}/{servidor.jogadoresTotais}
              </span>
              <span className="servidor-info">{servidor.ping} ms</span>
            </div>
          </a>
        ))}
      </div>

      <footer className="servidores-footer">
        <button className="footer-botao">Change filters</button>
        <button className="footer-botao">Stop refresh</button>
        <p className="footer-status">Getting new server list...</p>
      </footer>
    </div>
  );
};

export default Servidores;
