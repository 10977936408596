// src/data/servidoresData.js

export const comunidades = [
    {
      id: 1,
      nome: 'dMar.io [Comunidade de desenvolvimento]',
      jogo: 'Discord',
      jogadoresOnline: 12,
      jogadoresTotais: 27,
      ping: 12,
      link: 'https://dsc.gg/dmario'
    },
    {
      id: 2,
      nome: 'DOGMA - Plataforma Web3',
      jogo: 'Discord',
      jogadoresOnline: 22,
      jogadoresTotais: 200,
      ping: 45,
      link: 'https://discord.com/invite/exemplo2'
    }
  ];
  
  export const jogos = [
    {
      id: 3,
      nome: 'Cidade Zion',
      jogo: 'Jogo XYZ',
      jogadoresOnline: 10,
      jogadoresTotais: 50,
      ping: 30,
      link: 'https://servidordejogos.com/exemplo1'
    },
    {
      id: 4,
      nome: 'Cosmicraft',
      jogo: 'Jogo ABC',
      jogadoresOnline: 5,
      jogadoresTotais: 20,
      ping: 80,
      link: 'https://servidordejogos.com/exemplo2'
    },
    {
        id: 5,
        nome: 'Cosmicraft',
        jogo: 'Jogo ABC',
        jogadoresOnline: 5,
        jogadoresTotais: 20,
        ping: 80,
        link: 'https://servidordejogos.com/exemplo2'
    }
  ];
  